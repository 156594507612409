<template>
  <div id="app">
    <router-view class="router-view" />
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  watch: {},
};
</script>

<style lang="less">
.container {
  padding: 0 16px;
}
.van-nav-bar {
  z-index: 9999;
  .van-icon {
    color: #000000;
    font-size: 24px;
  }
  .van-nav-bar__title {
    font-size: 18px;
  }
}
</style>
